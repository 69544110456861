import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { IonRouterLink, IonSpinner, RouterDirection, useIonLoading } from '@ionic/react';
import React, { PropsWithChildren } from 'react';
import { BsDownload } from 'react-icons/bs';

import { downloadAndOpenFile } from '../../utils/misc-util';

import './B1Button.scss';

interface ButtonProps extends PropsWithChildren {
  className?: string;
  href?: string;
  routerDirection?: RouterDirection;
  unmountComponent?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  iconOnly?: boolean;
  disabled?: boolean;
  loading?: boolean;
  click?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement | HTMLIonRouterLinkElement>;
  slot?: string;
  external?: boolean;
  downloadAndOpen?: boolean;
}

const B1Button: React.FC<ButtonProps> = (buttonProps) => {
  const loading = useIonLoading();
  const classNames = ['b1-button', 'scale-effect'];

  if (buttonProps.className) {
    classNames.push(buttonProps.className);
  }

  if (buttonProps.iconOnly) {
    classNames.push('icon-only');
  }

  const handleClick = (
    e: React.MouseEvent<
      HTMLButtonElement | HTMLAnchorElement | HTMLIonRouterLinkElement,
      MouseEvent
    >
  ) => {
    if (Capacitor.isNativePlatform() && buttonProps.href) {
      if (buttonProps.downloadAndOpen) {
        e.preventDefault();

        downloadAndOpenFile(buttonProps.href, loading);
      } else if (buttonProps.external) {
        e.preventDefault();

        Browser.open({
          url: buttonProps.href,
          windowName: '_blank',
          presentationStyle: 'popover',
        });
      }
    }

    e.currentTarget.blur();

    if (buttonProps.click) {
      buttonProps.click(e);
    }
  };

  if (buttonProps.href) {
    if (buttonProps.external || buttonProps.downloadAndOpen) {
      return (
        <a
          className={classNames.join(' ')}
          href={buttonProps.href}
          onClick={handleClick}
          slot={buttonProps.slot}
          target='_blank'
          rel='noreferrer'
        >
          {buttonProps.loading && <IonSpinner />}
          {buttonProps.downloadAndOpen && <BsDownload />}
          {buttonProps.children}
        </a>
      );
    } else {
      return (
        <IonRouterLink
          className={classNames.join(' ')}
          routerLink={buttonProps.href}
          routerDirection={buttonProps.routerDirection}
          onClick={handleClick}
          slot={buttonProps.slot}
          routerOptions={{ unmount: buttonProps.unmountComponent }}
        >
          <div className='b1-button-inner'>
            {buttonProps.loading && <IonSpinner />}
            {buttonProps.children}
          </div>
        </IonRouterLink>
      );
    }
  }

  return (
    <button
      className={classNames.join(' ')}
      type={buttonProps.type ?? 'button'}
      onClick={handleClick}
      disabled={buttonProps.disabled || buttonProps.loading}
      slot={buttonProps.slot}
    >
      {buttonProps.loading && <IonSpinner />}
      {buttonProps.children}
    </button>
  );
};

export default B1Button;
