import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { CourseType } from '../../../api/interfaces/course';
import UserCourse from '../../../api/interfaces/user-course';
import AllInOneCourseCard from '../../../components/course/AllInOneCourseCard';
import DashboardWrapper from '../dashboard-wrapper/DashboardWrapper';

const SKNDashboard: React.FC = () => {
  const [userCourses, setUserCourses] = useState<UserCourse[]>([]);
  const [coursesLoading, setCoursesLoading] = useState(true);

  return (
    <DashboardWrapper
      courseType={CourseType.SKN}
      onUserCoursesLoaded={(courses) => {
        setUserCourses(courses);
        setCoursesLoading(false);
      }}
    >
      <Row className='g-3 course-cards'>
        <Col xl='4'>
          <AllInOneCourseCard
            userCourse={userCourses.find((c) => c.identifier === CourseType.SKN)}
            hasPromotionImage
            hasChevronIcon
            className='course-card'
            loading={coursesLoading}
            hasProgress
          />
        </Col>
        <Col xl='4'>
          <AllInOneCourseCard
            locked={userCourses.find((c) => c.identifier === CourseType.SKN)?.expired}
            link='/course/skn/smartmode'
            icon='assets/icons/learn-mode.svg'
            title='Smartmodus'
            description='Hier kannst Du alle Fragen, die in der Prüfung vorkommen können, effizient lernen.'
            className='smartmode-card'
            loading={coursesLoading}
          />
        </Col>
        <Col xl='4'>
          <Row className='g-3'>
            <Col sm='12'>
              <AllInOneCourseCard
                locked={userCourses.find((c) => c.identifier === CourseType.SKN)?.expired}
                link='/course/skn/exam-training'
                icon='assets/icons/exam-simulation.svg'
                title='Prüfungssimulation'
                description='Hier kannst Du mit den offiziellen Prüfungsbögen in einem echten Prüfungsszenario lernen.'
                loading={coursesLoading}
              />
            </Col>
            <Col sm='12'>
              <AllInOneCourseCard
                locked={userCourses.find((c) => c.identifier === CourseType.SKN)?.expired}
                link='/course/skn/exam-booking'
                icon='assets/icons/BS1_Icon_Exam_Booking.svg'
                title='Prüfung buchen'
                description='Hier kannst Du Deine Prüfung buchen.'
                loading={coursesLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardWrapper>
  );
};

export default SKNDashboard;
