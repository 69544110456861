import { RouterOptions, useIonRouter, useIonViewWillEnter } from '@ionic/react';
import { DateTime } from 'luxon';
import React, { FormEvent, useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { BiPencil, BiUser } from 'react-icons/bi';
import { BsArrowLeft } from 'react-icons/bs';

import { createEvent, deleteAccount, getAccountInfo } from '../../api/account/account';
import { getAvatars, setAvatar } from '../../api/account/avatar';
import { AccountInfo, AccountStatus, Avatar } from '../../api/interfaces/account-info';
import B1Button from '../../components/buttons/B1Button';
import CustomLink from '../../components/custom-link/CustomLink';
import EasterEgg from '../../components/easter-egg/EasterEgg';
import B1Input from '../../components/forms/B1Input';
import { FeedbackClass } from '../../components/forms/forms';
import B1Modal from '../../components/modal/B1Modal';
import FeedbackModal from '../../components/modal/feedback-modal/FeedbackModal';
import ReferralModal from '../../components/modal/referral-modal/ReferralModal';
import RequestPushPermissionModal from '../../components/modal/request-push-permission-modal/RequestPushPermissionModal';
import useWindowSize from '../../hooks/window-size-hook';
import { AuthContext } from '../../providers/AuthProvider';
import { isApiError } from '../../utils/api-util';
import Layout from '../Layout';

import './Account.scss';

const Account: React.FC = () => {
  const { logout, getUserData, isAuthenticated, refresh, snowfallEnabled, toggleSnowfall } =
    useContext(AuthContext);
  const [accountInfo, setAccountInfo] = useState<AccountInfo>();
  const [userState, setUserState] = useState<AccountStatus>();
  const [avatars, setAvatars] = useState<Avatar[]>([]);
  const [avatarSexSelection, setAvatarSexSelection] = useState<string>('m');
  const [selectedAvatar, setSelectedAvatar] = useState<number | null>(null);
  const [snowfallActive, setSnowfallActive] = useState(false);

  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [referralModalOpen, setReferralModalOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [pushPermissionModalOpen, setPushPermissionModalOpen] = useState(false);
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState(false);
  const [deleteAccountPassword, setDeleteAccountPassword] = useState('');
  const [deleteAccountPasswordFeedback, setDeleteAccountPasswordFeedback] = useState('');
  const [deleteAccountPasswordFeedbackClass, setDeleteAccountPasswordFeedbackClass] =
    useState<FeedbackClass>();

  const windowSize = useWindowSize();
  const ionRouter = useIonRouter();

  useEffect(() => {
    getUserData().then((state) => {
      setUserState(state);

      const active = state?.feature_flags.find((flag) => flag.name === 'snowfall')?.active;

      setSnowfallActive(active ?? false);
    });
  }, [isAuthenticated, getUserData]);

  useIonViewWillEnter(() => {
    getAccountInfo().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      setAccountInfo(res);
    });

    getAvatars().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      setAvatars(res);
    });
  }, []);

  useEffect(() => {
    if (!userState || avatars.length === 0) return;

    if (userState.avatar) {
      const userAvatar = avatars.find((a) => a.path === userState.avatar);

      if (!userAvatar) {
        console.warn('User avatar not found in avatars list.');
        return;
      }

      setAvatarSexSelection(userAvatar.sex);
    } else if (userState.sex) {
      setAvatarSexSelection(userState.sex);
    }
  }, [userState, avatars]);

  const handleSelectAvatar = () => {
    if (selectedAvatar === null) return;

    setAvatar(selectedAvatar).then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      setAvatarModalOpen(false);
      refresh();
    });
  };

  const renderBirthdayTooltip = (props: OverlayInjectedProps) => {
    return (
      <Tooltip className='b1-tooltip' {...props}>
        Wenn Dein Geburtsdatum nicht stimmt, kontaktiere bitte den Support oder schreibe eine E-Mail
        an <a href='mailto:info@bootsschule1.de'>info@bootsschule1.de</a>
      </Tooltip>
    );
  };

  const handleDeleteAccount = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!deleteAccountPassword) {
      setDeleteAccountPasswordFeedback('Bitte gib ein Passwort ein.');
      setDeleteAccountPasswordFeedbackClass('warning');
      return;
    }

    deleteAccount(deleteAccountPassword).then((res) => {
      if (isApiError(res)) {
        if (res.statusCode === 400 || res.statusCode === 401) {
          setDeleteAccountPasswordFeedback('Falsches Passwort.');
          setDeleteAccountPasswordFeedbackClass('danger');
        } else {
          console.error(res);
          setDeleteAccountPasswordFeedback('Unbekannter Fehler.');
          setDeleteAccountPasswordFeedbackClass('warning');
        }
        return;
      }

      logout();

      ionRouter.push('/login', 'root', 'replace', {
        unmount: true,
        from: 'delete-account',
      } as RouterOptions);
    });
  };

  return (
    <Layout contentClassName='account' backButton>
      <div className='account-container'>
        <div className='account-heading'>
          <button
            type='button'
            className='avatar'
            title='Avatar auswählen'
            onClick={() => setAvatarModalOpen(true)}
          >
            {userState?.avatar ? <img src={userState.avatar} alt='' /> : <BiUser />}
            <div className='edit-circle scale-effect'>
              <BiPencil />
            </div>
          </button>
          <div className='user-infos'>
            <h1 className='no-hyphens'>
              {accountInfo?.user.firstname} {accountInfo?.user.lastname}
            </h1>
            <p>
              Registriert am:{' '}
              {accountInfo &&
                DateTime.fromISO(accountInfo.user.registration_date).toLocaleString(
                  DateTime.DATE_FULL
                )}
            </p>
          </div>
        </div>
        <Row className='g-3'>
          <Col md='5'>
            <div className='card personal-data-card'>
              <div className='b1-card-header'>
                <h5>Persönliche Daten</h5>
              </div>

              <div>
                <div className='d-flex justify-content-start align-items-center mb-2'>
                  <label htmlFor='birthday' className='me-3'>
                    Geburtstag
                  </label>
                  <OverlayTrigger
                    placement={!windowSize.width || windowSize.width < 576 ? 'bottom' : 'right'}
                    overlay={renderBirthdayTooltip}
                    trigger={['hover', 'focus']}
                  >
                    <button
                      type='button'
                      className='b1-button button-sm button-transparent-dark scale-effect birthday-help-button'
                    >
                      ?
                    </button>
                  </OverlayTrigger>
                </div>
                <p id='birthday'>
                  {accountInfo?.user.date_of_birth &&
                    DateTime.fromISO(accountInfo.user.date_of_birth).toLocaleString(
                      DateTime.DATE_SHORT
                    )}
                </p>
              </div>

              <hr />

              <div>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                  <label htmlFor='email'>E-Mail</label>
                  <B1Button
                    className='button-sm button-transparent-dark'
                    href='/account/change-email'
                  >
                    Ändern
                  </B1Button>
                </div>
                <p id='email'>{accountInfo?.user.email}</p>
              </div>

              <hr />

              <div className='d-flex justify-content-between align-items-center'>
                <p>Passwort</p>
                <B1Button
                  className='button-sm button-transparent-dark'
                  href='/account/change-password'
                >
                  Ändern
                </B1Button>
              </div>
            </div>
          </Col>
          <Col md='7'>
            <div className='card order-history-card'>
              <div className='b1-card-header'>
                <h5>Bestellhistorie</h5>
                <div className='card-header-buttons'>
                  <B1Button
                    className='button-sm button-transparent-outline'
                    href='/account/redeem-code'
                  >
                    Code einlösen
                  </B1Button>
                </div>
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Kurs</th>
                    <th>Eingelöst am</th>
                    <th>Gültig bis</th>
                  </tr>
                </thead>
                <tbody>
                  {accountInfo &&
                    accountInfo.codeCourses.map((code, index) => (
                      <tr key={index}>
                        <td>{code.code}</td>
                        <td>{code.course}</td>
                        <td>
                          {DateTime.fromISO(code.claimed_at).toLocaleString(DateTime.DATE_SHORT)}
                        </td>
                        <td>
                          {code.valid_until
                            ? DateTime.fromISO(code.valid_until).toLocaleString(DateTime.DATE_SHORT)
                            : '–'}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md='12'>
            <div className='card'>
              <div className='b1-card-header'>
                <h5>Einstellungen</h5>
              </div>

              <Row className='gx-5 gy-3'>
                <Col lg='6'>
                  <div className='d-flex justify-content-between align-items-center gap-3'>
                    <p className='hyphens'>Push-Benachrichtigungen</p>
                    <B1Button
                      className='button-sm button-transparent-dark'
                      click={() => setPushPermissionModalOpen(true)}
                    >
                      Konfigurieren
                    </B1Button>
                  </div>
                </Col>
                {snowfallActive && (
                  <Col lg='6'>
                    <div className='d-flex justify-content-between align-items-center gap-3'>
                      <p className='hyphens'>Schneefall</p>
                      <B1Button
                        className='button-sm button-transparent-dark'
                        click={() => toggleSnowfall()}
                      >
                        {snowfallEnabled ? 'Deaktivieren' : 'Aktivieren'}
                      </B1Button>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
          <Col md='7'>
            <div className='affiliate-card'>
              <p className='mb-2'>
                {/* Gemeinsam geht&apos;s besser, wirb einen Freund und gewinn&apos; ein Boot */}
                Gemeinsam zur Prüfung – wirb Deine Freunde
              </p>
              <div className='affiliate-buttons'>
                {accountInfo?.user.kwk_coupon && (
                  <B1Button
                    className='button-sm button-reverse'
                    click={() => setReferralModalOpen(true)}
                  >
                    🎁 Wirb Deine Freunde
                  </B1Button>
                )}
                <B1Button
                  className='button-sm'
                  href='/gluecksrad'
                  click={() => createEvent('wheel_clicked')}
                >
                  🎰 Glücksrad
                </B1Button>
              </div>
            </div>
          </Col>
          <Col md='5'>
            <div className='card feedback-card'>
              <img src='assets/icons/speech-bubble.svg' />
              <div className='d-flex justify-content-between align-items-center'>
                <p>Teile Deine Ideen mit uns</p>
                <B1Button
                  className='button-sm button-transparent'
                  click={() => setFeedbackModalOpen(true)}
                >
                  Feedback
                </B1Button>
              </div>
            </div>
          </Col>
        </Row>
        <div className='account-footer'>
          <div className='legal-links'>
            <CustomLink href='https://www.bootsschule1.de/impressum/'>Impressum</CustomLink>
            <CustomLink href='https://bootsschule1.de/datenschutzerklaerung/'>
              Datenschutzerklärung
            </CustomLink>
          </div>
          <B1Button
            className='button-sm button-transparent-dark'
            click={() => {
              setConfirmDeleteAccount(false);
              setDeleteAccountModalOpen(true);
            }}
          >
            Account löschen
          </B1Button>
        </div>
        <div className='copyright-footer mt-5'>
          <EasterEgg />
          <p>&copy; {DateTime.now().year} Bootsschule1 GmbH & Co. KG</p>
        </div>
      </div>

      <B1Modal
        className='avatar-modal'
        open={avatarModalOpen}
        onModalClose={() => setAvatarModalOpen(false)}
      >
        <h2 className='mb-4'>Avatar auswählen</h2>
        <div className='gender-selection'>
          <button
            type='button'
            className={avatarSexSelection === 'm' ? 'selected' : ''}
            onClick={() => setAvatarSexSelection('m')}
          >
            Männer
          </button>
          <button
            type='button'
            className={avatarSexSelection === 'w' ? 'selected' : ''}
            onClick={() => setAvatarSexSelection('w')}
          >
            Frauen
          </button>
          {avatars.find((a) => a.sex === 'd') && (
            <button
              type='button'
              className={avatarSexSelection === 'd' ? 'selected' : ''}
              onClick={() => setAvatarSexSelection('d')}
            >
              Diverse
            </button>
          )}
        </div>
        <div className='avatar-selection'>
          {avatars
            .filter((a) => a.sex === avatarSexSelection)
            .map((avatar) => (
              <button
                key={avatar.id}
                type='button'
                className='avatar scale-effect'
                onClick={(e) => {
                  setSelectedAvatar(avatar.id);
                  e.currentTarget.blur();
                }}
              >
                <img className='avatar-image' src={avatar.path} alt='' />
                {(selectedAvatar === avatar.id ||
                  (selectedAvatar === null && userState?.avatar === avatar.path)) && (
                  <img
                    className='selected-avatar-circle'
                    src='assets/icons/selected-avatar-circle.svg'
                  />
                )}
              </button>
            ))}
        </div>
        <B1Button className='button-reverse select-avatar' click={() => handleSelectAvatar()}>
          Speichern
        </B1Button>
      </B1Modal>

      <RequestPushPermissionModal
        open={pushPermissionModalOpen}
        setOpen={setPushPermissionModalOpen}
      />

      <ReferralModal
        open={referralModalOpen}
        setOpen={setReferralModalOpen}
        accountInfo={accountInfo}
      />

      <FeedbackModal open={feedbackModalOpen} setOpen={setFeedbackModalOpen} />

      <B1Modal
        className='delete-account-modal'
        open={deleteAccountModalOpen}
        onModalClose={() => setDeleteAccountModalOpen(false)}
      >
        {!confirmDeleteAccount ? (
          <>
            <h1 className='emoji'>😨</h1>
            <h2>Account löschen</h2>
            <p className='mb-3'>Dieser Schritt lässt sich nicht rückgängig machen.</p>
            <B1Button className='button-block' click={() => setDeleteAccountModalOpen(false)}>
              <BsArrowLeft />
              Zurück
            </B1Button>
            <B1Button className='button-obscure' click={() => setConfirmDeleteAccount(true)}>
              Account löschen
            </B1Button>
          </>
        ) : (
          <>
            <h1 className='emoji'>❌</h1>
            <h2 className='no-hyphens'>Möchtest Du Deinen Account wirklich löschen?</h2>
            <form className='b1-form' onSubmit={handleDeleteAccount}>
              <input
                readOnly={true}
                type='text'
                id='username'
                autoComplete='username'
                value={accountInfo?.user.email}
                style={{ display: 'none' }}
              />
              <B1Input
                id='delete-account-password'
                label='Bitte bestätige Deine Accountlöschung mit Deinem Passwort.'
                type='password'
                autoComplete='current-password'
                placeholder='Passwort'
                value={deleteAccountPassword}
                onInput={(e) => setDeleteAccountPassword((e.target as HTMLInputElement).value)}
                feedback={deleteAccountPasswordFeedback}
                feedbackClass={deleteAccountPasswordFeedbackClass}
              />
              <B1Button type='submit' className='button-block button-red'>
                Account unwiderruflich löschen
              </B1Button>
            </form>
          </>
        )}
      </B1Modal>
    </Layout>
  );
};

export default Account;
