import React, { useContext, useEffect, useState } from 'react';
import { Snowfall as ReactSnowfall } from 'react-snowfall';

import useWindowSize from '../../hooks/window-size-hook';
import { AuthContext } from '../../providers/AuthProvider';

const Snowfall: React.FC = () => {
  const { isAuthenticated, getUserData, snowfallEnabled } = useContext(AuthContext);
  const [snowfallActive, setSnowfallActive] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    getUserData().then((state) => {
      const active = state?.feature_flags.find((flag) => flag.name === 'snowfall')?.active;

      setSnowfallActive(active ?? false);
    });
  }, [isAuthenticated, getUserData]);

  let snowflakeCount = 150;

  if (!snowfallEnabled || !snowfallActive) return <></>;

  if (width && width < 768) {
    snowflakeCount = 75;
  }

  return <ReactSnowfall snowflakeCount={snowflakeCount} style={{ zIndex: 0 }} />;
};

export default Snowfall;
