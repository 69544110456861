import { Device } from '@capacitor/device';

import { RegistrationData } from '../../reducers/registration-data-reducer';
import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import { AccountInfo, AccountStatus } from '../interfaces/account-info';

export const getAccountStatus = async () => {
  const device = await Device.getInfo();
  const deviceId = await Device.getId();

  const res = await makeApiRequest<AccountStatus>('POST', 'v2/account/status', undefined, {
    app_version: APP_VERSION,
    platform: device.platform,
    platform_version: device.osVersion,
    manufacturer: device.manufacturer,
    model: device.model,
    operating_system: device.operatingSystem,
    web_view_version: device.webViewVersion,
    ios_version: device.iOSVersion,
    android_sdk_version: device.androidSDKVersion,
    device_id: deviceId.identifier,
  });

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const login = async (email: string, password: string, stayLoggedIn = false) => {
  const res = await makeApiRequest('POST', 'v1/account/login', undefined, {
    email: email,
    password: password,
    stay_logged_in: stayLoggedIn,
  });

  if (isApiError(res)) {
    return res;
  }

  if (res.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const logout = async () => {
  const deviceId = await Device.getId();

  const res = await makeApiRequest('POST', 'v2/account/logout', undefined, {
    device_id: deviceId.identifier,
  });

  if (isApiError(res)) {
    return res;
  }

  if (res.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const register = async (registrationData: RegistrationData) => {
  const res = await makeApiRequest('POST', 'v1/account/register', undefined, registrationData);

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const getAccountInfo = async () => {
  const res = await makeApiRequest<AccountInfo>('GET', 'v2/account');

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const deleteAccount = async (password: string) => {
  const res = await makeApiRequest('POST', 'v1/account/delete-account', undefined, {
    password: password,
  });

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const createEvent = async (
  title: 'upsell_clicked' | 'extension_clicked' | 'easteregg_clicked' | 'wheel_clicked',
  data?: object
) => {
  const res = await makeApiRequest('POST', 'v1/account/event', undefined, {
    title: title,
    data: data,
  });

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const addPushToken = async (token: string) => {
  const deviceId = await Device.getId();

  const res = await makeApiRequest('POST', 'v1/account/push-token', undefined, {
    device_id: deviceId.identifier,
    token: token,
  });

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const sendReviewSubmitted = async () => {
  const deviceId = await Device.getId();

  const res = await makeApiRequest('POST', 'v1/account/review', undefined, {
    device_id: deviceId.identifier,
  });

  if (isApiError(res)) {
    return res;
  }

  return true;
};
