import { IonSkeletonText } from '@ionic/react';
import React from 'react';

import './ProgressBar.scss';

interface ProgressBarProps {
  className?: string;
  progress: number;
  showProgressNumber?: boolean;
  loading?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  className,
  progress,
  showProgressNumber,
  loading,
}) => {
  return (
    <>
      <div className={'progressbar' + (className ? ' ' + className : '')}>
        <div className='progress' style={{ width: progress * 100 + '%' }} />
      </div>
      {(showProgressNumber || loading) && (
        <>
          {!loading && (
            <p className='description progress-number'>{(progress * 100).toFixed(0)}%</p>
          )}
          {loading && (
            <IonSkeletonText
              animated={true}
              style={{ width: '40px', height: '16px', marginTop: '16px' }}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProgressBar;
