import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { CourseType } from '../../../api/interfaces/course';
import UserCourse from '../../../api/interfaces/user-course';
import AllInOneCourseCard from '../../../components/course/AllInOneCourseCard';
import DashboardWrapper from '../dashboard-wrapper/DashboardWrapper';

const BSPDDashboard: React.FC = () => {
  const [userCourses, setUserCourses] = useState<UserCourse[]>([]);
  const [coursesLoading, setCoursesLoading] = useState(true);

  const getSPCourse = () => {
    const spCourse = userCourses.find((c) => c.identifier === CourseType.SP);

    if (spCourse) {
      return {
        ...spCourse,
        identifier: CourseType.BSPDSP,
      } as UserCourse;
    }
  };

  return (
    <DashboardWrapper
      courseType={CourseType.BSPD}
      onUserCoursesLoaded={(courses) => {
        setUserCourses(courses);
        setCoursesLoading(false);
      }}
    >
      <Row className='g-3 course-cards'>
        <Col xl='8'>
          <Row className='g-3'>
            <Col xs='6'>
              <AllInOneCourseCard
                userCourse={userCourses.find((c) => c.identifier === CourseType.BSPD)}
                hasPromotionImage
                hasChevronIcon
                loading={coursesLoading}
                hasProgress
              />
            </Col>
            <Col xs='6'>
              <AllInOneCourseCard
                userCourse={getSPCourse()}
                hasPromotionImage
                hasChevronIcon
                loading={coursesLoading}
                hasProgress
              />
            </Col>
            <Col md='4'>
              <AllInOneCourseCard
                icon='assets/icons/BS1_Icon_Trips.svg'
                title='Praxis buchen'
                // description='Buche hier Deine Praxisstunden.'
                description='Diese Funktion steht leider noch nicht zur Verfügung.'
                // locked={userCourses.find((c) => c.identifier === CourseType.BSPD)?.expired}
                locked
                hasChevronIcon
                isCompact
                link='/course/bspd/practice-booking'
                loading={coursesLoading}
              />
            </Col>
            <Col md='4'>
              <AllInOneCourseCard
                icon='assets/icons/knots.svg'
                wideIcon
                title='Knoten'
                description='Übe hier die Knoten.'
                locked={userCourses.find((c) => c.identifier === CourseType.BSPD)?.expired}
                hasChevronIcon
                isCompact
                link='/course/bspd/knots'
                loading={coursesLoading}
              />
            </Col>
            <Col md='4'>
              <AllInOneCourseCard
                icon='assets/icons/BS1_Icon_Exam_Booking.svg'
                title='Prüfung buchen'
                // description='Hier kannst Du Deine Prüfung buchen.'
                description='Diese Funktion steht leider noch nicht zur Verfügung.'
                // locked={userCourses.find((c) => c.identifier === CourseType.BSPD)?.expired}
                locked
                hasChevronIcon
                isCompact
                link='/course/bspd/exam-booking'
                loading={coursesLoading}
              />
            </Col>
          </Row>
        </Col>
        <Col xl='4'>
          <Row className='g-3'>
            <Col sm='12'>
              <AllInOneCourseCard
                locked={userCourses.find((c) => c.identifier === CourseType.BSPD)?.expired}
                link='/course/bspd/smartmode'
                icon='assets/icons/learn-mode.svg'
                title='Smartmodus'
                description='Hier kannst Du alle Fragen, die in der Prüfung vorkommen können, effizient lernen.'
                loading={coursesLoading}
              />
            </Col>
            <Col sm='12'>
              <AllInOneCourseCard
                locked={userCourses.find((c) => c.identifier === CourseType.BSPD)?.expired}
                link='/course/bspd/exam-training'
                icon='assets/icons/exam-simulation.svg'
                title='Prüfungssimulation'
                description='Hier kannst Du mit den offiziellen Prüfungsbögen in einem echten Prüfungsszenario lernen.'
                loading={coursesLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardWrapper>
  );
};

export default BSPDDashboard;
